import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["select", "city", "country"]
  static values = {
    url: String,
    param: String,
    params: String,
    urls: String
  }

  
  change(event) {
    console.log(this.selectTarget)
    let params = new URLSearchParams()
    params.append(this.paramValue, event.target.selectedOptions[0].value)
    params.append("target", this.selectTarget.id)
    params.append("state", this.selectTarget.value)

    get(`${this.urlValue}?${params}`, {
      responseKind: "turbo-stream"
    })
  }

  changeCountry(event) {
    console.log(this.selectTarget)
    let params = new URLSearchParams()
    params.append("country", this.countryTarget.value)
    params.append("target", this.selectTarget.id)
    params.append("state", this.selectTarget.value)

    get(`${this.urlValue}?${params}`, {
      responseKind: "turbo-stream"
    })
  }

  changeCity(event) {
    let params = new URLSearchParams()
    // params.append('country', this.paramValue)
    params.append("country", this.countryTarget.value)
    params.append("state", event.target.value)
    params.append("target", this.cityTarget.id)

    get(`/manager/cities?${params}`, {
      responseKind: "turbo-stream"
    })
  }
}