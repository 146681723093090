import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["frame"]
 
  static values = {
    url: String,
    param: String,
    next: String
  }

  
  
  upload(event) {
    let counter = this.element.childElementCount
    let params = new URLSearchParams()
    params.append("target", counter)
    params.append("src", URL.createObjectURL(event.currentTarget.files[0]))

    get(`${this.urlValue}?${params}`, {
      responseKind: "turbo-stream"
    })
  }

  edit(event) {
    let frame = document.getElementById(`frame_${event.currentTarget.dataset.id}`);
    console.log(frame);
    frame.src=URL.createObjectURL(event.currentTarget.files[0]);
  } 
}