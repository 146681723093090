import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  
    open() {
      $('.ui.long.modal.turbo-frame').modal('show')
      $('.languages .ui.dropdown').dropdown({maxSelections: 3});
    }

    close(event) {
      if (event.detail.success) {
        $('.ui.long.modal.turbo-frame').modal('hide')
      }
    }
    cancel(e) {
      e.preventDefault()
      $('.ui.long.modal.turbo-frame').modal('hide')
    }
}