import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  
    connect() {
      $('.contact-host.dropdown').dropdown({
        action: "nothing",
        direction: 'downward',
        transition: 'slide down', 
      });
    }   
}