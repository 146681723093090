import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container"]

  
  connect() {
    let messageBody = document.querySelector(`#${this.containerTarget.id}`);
    messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
  }

  scrollToBottom(event) {
    let messageBody = document.querySelector(`#${this.containerTarget.id}`);
    let textArea = document.querySelector("#conversation_body");
    messageBody.scrollTop = (messageBody.scrollHeight - messageBody.clientHeight) + 50;
  }
 
}