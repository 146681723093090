import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  
  connect() {
    console.log('noitification', this);
  }

  remove(event) {
    console.log(event.target.firstChild);
    event.target.removeChild(event.target.firstChild)

  }    
}