import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  
    connect() {
      $('.ui.demo.menu').on("click", function () {
        this.classList.toggle("active");
        $('.ui.sidebar').sidebar('setting', 'transition', 'overlay').sidebar('toggle');
        $('.ui.sidebar').sidebar('setting', 'mobileTransition', 'overlay').sidebar('toggle');
      });
      
      $('.pusher').on("click", function () {
        const el = document.querySelector('.ui.demo.menu');
        el.classList.remove("active");
      });
    }
    
    
}