// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


require("@rails/ujs").start();
require("turbolinks").start();
// require("@rails/activestorage").start();

import "@rails/activestorage"

require("channels");
require( "jquery" );


require("trix");
require("@rails/actiontext");
import "@hotwired/turbo-rails"

// require("chartkick/chart.js")
// require("chart.js")
// var f = require("d3")
// console.log(f)
// window.d3 = d3;

// import "chartkick/chart.js"

import "controllers";
// require("slick-carousel")

// import "slick-carousel/slick/slick.scss"
// import "slick-carousel/slick/slick-theme.scss"


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import "src/application.scss";


import flatpickr from "flatpickr";

// require("flatpickr/dist/themes/dark.css");
require("flatpickr/dist/themes/material_orange.css");


require("./components/semantic.js");

import Swiper, { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';
Swiper.use([Autoplay, EffectFade]);
import "swiper/swiper.scss";
import 'swiper/swiper-bundle.css';
import 'swiper/modules/effect-fade/effect-fade.scss'







document.addEventListener("turbo:load", () => {

  const availAppt = document.getElementById("availAppt");
  if (availAppt) {
    const upcomingAppointments = JSON.parse(availAppt.dataset.appointments);
    const maxDate = upcomingAppointments.length-1;
    const availSpots = JSON.parse(document.getElementById("availSpots").dataset.appointments);

    const guestNumber = document.getElementById("guestNumber");
    // const fp = document.getElementById("start_date");

    flatpickr("#start_date", {
      mode: "single",
      position: "above center",
      minDate: upcomingAppointments[0],
      maxDate: upcomingAppointments[maxDate],
      enable: upcomingAppointments,
      dateFormat: "Y-m-d",
      inline: true,
      onChange: function(selectedDates, dateStr, instance) {
        document.querySelector(".spots").innerHTML = `${availSpots[dateStr]} spot(s) available`
        const dt =  new Date(dateStr).toDateString();
        document.querySelector(".selected-dates").innerHTML = `${dt}`;
        if (guestNumber.value > availSpots[dateStr]) {
          guestNumber.value = 1;
        }
        guestNumber.setAttribute("max",availSpots[dateStr]);
      },
    });
  }

  var homeSlider = new Swiper(".home-signup .swiper", {
    loop: true,
    fadeEffect: { crossFade: true },
    slidesPerView: 1,
    centeredSlides: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: true,
    },
    speed: 2000,
    effect: "fade",
  });
 

  const swiper = new Swiper('.services-live .swiper', {

    modules: [Navigation, Pagination],
    // Optional parameters
    // direction: 'vertical',
    // loop: true,

    loop: $(".swiper .swiper-slide").length != 1,
  
    // If we need pagination
    pagination: {
      el: ".swiper-pagination",
      type: "fraction",
    },
    
  
    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    on: {
      afterInit: function() {
          if (this.slides.length == 1) {
            $(".swiper  .swiper-pagination").hide();
            this.el.classList.add("one-pic");
          }
      },
    },
  
    // And if we need scrollbar
    scrollbar: {
      el: '.swiper-scrollbar',
    },

    breakpoints: {
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
    },
  });
  

  var destinationSlider = new Swiper(".destination-slider .swiper", {
    
    modules: [Navigation, Pagination],
    loop: false,
    slidesPerView: 1,
    spaceBetween: 0,
    centeredSlides: true,
    
    pagination: {
      el: ".swiper-pagination",
      dynamicBullets: true,
      clickable: true,
    },
  });

  var experiencesSlider = new Swiper(".experiences-slider .swiper", {
    
    modules: [Navigation, Pagination],
    // loop: false,
    // slidesPerView: 4,
    // spaceBetween: 0,
    // centeredSlides: true,
    // loop: true,
    
    pagination: {
      el: ".swiper-pagination",
      dynamicBullets: true,
      clickable: true,
    },

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    breakpoints: {
      600: {
        slidesPerView: 2,
        // slidesPerGroup: 1,
        // spaceBetween: 0,
        spaceBetween: 30,
        // centeredSlides: true,
      },
      950: {
        slidesPerView: 3,
        // slidesPerGroup: 2,
        spaceBetween: 30,
      }
    },
  });
  
 
  // $('.home .fade-slider').slick({
  //   arrows: false,
  //   infinite: true,
  //   speed: 2000,
  //   fade: true,
  //   autoplay: true,
  //   cssEase: 'linear'
  // });

  const scrollTop = document.getElementById("scroll-top");

  if (scrollTop) {
    scrollTop.addEventListener("click", (event) => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
  }

  

  const appointments = document.getElementById("appointments");
  if (appointments) {
    const existingAppointments = JSON.parse(
      document.querySelector("#appointments").dataset.appointments
    );

    flatpickr(".datepicker", {
      mode: "multiple",
      minDate: "today",
      disable: existingAppointments,
      dateFormat: "Y-m-d",
      inline: false,
    });
  }

  const appointmentsEdit = document.getElementById("appointments-edit");
  if (appointmentsEdit) {
    const existingAppointments = JSON.parse(
      document.querySelector("#appointments-edit").dataset.appointments
    );

    flatpickr(".datepicker-edit", {
      mode: "single",
      minDate: "today",
      disable: existingAppointments,
      dateFormat: "Y-m-d",
    });
  }

  

  $(".ui.dropdown").dropdown({ action: "hide" });

  

  $(".ui.dropdown.select").dropdown();
  $(".signup .ui.dropdown").dropdown();
  $(".ui.dropdown.currency").dropdown();

  $(".ui.dropdown.host-signup").dropdown();

 $('.search-by .ui.dropdown').dropdown({
    action: "hide",
    direction: 'downward',
    transition: 'slide down',
  });
  $(".ui.accordion").accordion();

  $(".ui.secondary .item").tab();
  $(".terms .menu .item").tab();
  $(".menu-first .item").tab();
  $('.terms .ui.sticky').sticky({
    context: '.terms .sticky-context'
  });
  $('.statistics .ui.sticky').sticky({
    context: '.statistics .sticky-context'
  });
  $(".ui.message .close").on("click", function () {
    $(this).closest(".message").transition("fade");
  });


  $('.services-live .language .ui.dropdown')
  .dropdown({
    maxSelections: 3
  });

  $('.languages .ui.dropdown')
  .dropdown({
    maxSelections: 3
  });

  
  $('.contact-host.dropdown').dropdown({
    action: "nothing",
    direction: 'downward',
    transition: 'slide down', 
  });


  $('.ui.modal.payment').modal({
    blurring: true,
    closable  : false,
  }).modal('show');

  $('.hide-modal').modal('hide');


  flatpickr(".date-request", {
    mode: "multiple",
    minDate: "today",
    dateFormat: "Y-m-d",
    inline: false,
  });

  

  

  // $('.trial-index .ui.sticky.navigation')
  // .sticky({
  //   context: '#navigation',
  //   onScroll: function () {

  //     if (window.pageYOffset < 50) {
  //       this.classList.remove("onscroll");

  //     }else {
  //       this.classList.add("onscroll");
  //     }
  //   }
  // });

  // $('.trial-index .ui.sticky.navigation').sticky('refresh');
 

  var previewImage = function (event) {
    var reader = new FileReader();
    reader.onload = function () {
      var output = event.target.nextElementSibling;
      output.src = reader.result;
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const selectElement = document.querySelectorAll(".photo");

  $(".photo").on("change", previewImage);

  // $(".map.location, .close-map").on("click", function (e) {
  //   e.preventDefault();
  //   $(".activity-map").toggleClass("visible");
  // });

  $(".ll.search").on("click", function () {
    $(".form.search").transition('scale')
  });

  // $(".close-map .icon").on("click", function () {
  //   console.log('hey');
  //   $(".activity-map").removeClass("visible");
  // });

  // $(".ui.checkbox").checkbox({
  //   onChecked: function () {
  //     console.log("onChecked called<br>", $(".ui.checkbox").parent());
  //     // this.parentElement.removeClass("error");

  //     // this.parent().removeClass("error");
  //     $(".ui.checkbox").parent().removeClass("error");
  //   },
  //   onUnchecked: function () {
  //     console.log("onChecked called<br>");
  //     $(".ui.checkbox").parent().addClass("error");
  //   },
  // });

  

  var meta = document.querySelector("meta[name=language]");
  var language = meta.getAttribute("content");
  document.documentElement.setAttribute("lang", language);
  //semantic ui
  $(".rating").rating();

  $.fn.form.settings.rules.adminLevel = function (value, adminLevel) {
    console.log(value, $(this), this[0], adminLevel);
  };

  $(".host .ui.form").form({
    fields: {
      email: {
        identifier: "host[email]",
        rules: [
          {
            type: "email",
            prompt: "Please enter a valid e-mail",
          },
        ],
      },
      name: {
        identifier: "host[name]",
        rules: [
          {
            type: "empty",
            prompt: "Can't be blank",
          },
        ],
      },
      password: {
        identifier: "host[password]",
        rules: [
          {
            type: "empty",
            prompt: "Can't be blank",
          },
          {
            type: "minLength[6]",
            prompt: "Must be at least 6 characters",
          },
        ],
      },
      password_confirmation: {
        identifier: "host[password_confirmation]",
        rules: [
          {
            type: "match[host[password]]",
            prompt: "Must match password field",
          },
          {
            type: "empty",
            prompt: "Must have a value",
          },
          {
            type: "minLength[6]",
            prompt: "Must be at least 6 characters",
          },
        ],
      },
      terms: {
        identifier: "host[terms_of_service]",
        rules: [
          {
            type: "checked",
            prompt: "Please check the checkbox",
          },
        ],
      },
      phone: {
        identifier: "host[profile_attributes][phone]",
        rules: [
          {
            type: "empty",
            prompt: "Can't be blank ",
          },
        ],
      },
      address: {
        identifier: "host[profile_attributes][address]",
        rules: [
          {
            type: "empty",
            prompt: "Can't be blank",
          },
        ],
      },
      country: {
        identifier: "host[profile_attributes][country]",
        rules: [
          {
            type: "empty",
            prompt: "Can't be blank ",
          },
        ],
      },
      province: {
        identifier: "host[profile_attributes][province]",
        rules: [
          {
            type: "empty",
            prompt: "Can't be blank ",
          },
        ],
      },
      city: {
        identifier: "host[profile_attributes][city]",
        rules: [
          {
            type: "empty",
            prompt: "Can't be blank ",
          },
        ],
      },
      // photo_second: {
      //   identifier: "activity[photo_second]",
      //   rules: [
      //     {
      //       type: "empty",
      //       prompt: "Image can not be blank ",
      //     },
      //   ],
      // },
      // photo_first: {
      //   identifier: "activity[photo_first]",
      //   rules: [
      //     {
      //       type: "empty",
      //       prompt: "Image can not be blank ",
      //     },
      //   ],
      // },
      // photo_third: {
      //   identifier: "activity[photo_third]",
      //   rules: [
      //     {
      //       type: "empty",
      //       prompt: "Image can not be blank ",
      //     },
      //   ],
      // },
      // photo_forth: {
      //   identifier: "activity[photo_forth]",
      //   rules: [
      //     {
      //       type: "empty",
      //       prompt: "Image can not be blank ",
      //     },
      //   ],
      // },
      // description_second: {
      //   identifier: "activity[second_description]",
      //   rules: [
      //     {
      //       type: "empty",
      //       prompt: "Second description can not be blank ",
      //     },
      //   ],
      // },
    },

    inline: true,
    on: "blur",
  });

  $(".ui.form.guest").form({
    fields: {
      email: {
        identifier: "guest[email]",
        rules: [
          {
            type: "email",
            prompt: "Please enter a valid e-mail",
          },
        ],
      },
      name: {
        identifier: "guest[name]",
        rules: [
          {
            type: "empty",
            prompt: "Name can not be blank ",
          },
        ],
      },
      password: {
        identifier: "guest[password]",
        rules: [
          {
            type: "empty",
          },
          {
            type: "minLength[6]",
          },
        ],
      },
      password_confirmation: {
        identifier: "guest[password_confirmation]",
        rules: [
          {
            type: "match[guest[password]]",
            prompt: "Confirmation must match password field",
          },
          {
            type: "empty",
          },
          {
            type: "minLength[6]",
          },
        ],
      },
      terms: {
        identifier: "guest[terms_of_service]",
        rules: [
          {
            type: "checked",
            prompt: "Please check the checkbox",
          },
        ],
      },
    },

    inline: true,
    on: "blur",
  });

  $(".ui.checkbox").checkbox();
});

// Mama f-ing jan
document.addEventListener("turbo:before-cache", () => {
  

  const fp = flatpickr("#start_date", {});

  

});



document.addEventListener("turbo:render",  () => {
  // console.log("frame render")
  
  
  const fp = flatpickr(".date-request", {
    mode: "multiple",
    minDate: "today",
    dateFormat: "Y-m-d",
    inline: false,
  });

 

});

document.addEventListener("turbo:frame-load",  () => {
  console.log("frame load")

  $('.dropdown.contact-host').on( "click", function() {
    console.log( "clicked");
  });

  const fp = flatpickr(".date-request", {
    mode: "multiple",
    minDate: "today",
    dateFormat: "Y-m-d",
    inline: false,
  });

  // const fp = flatpickr("#start_date", {});

 

});

document.addEventListener("turbo:submit-start", function (event) {

  // console.log("starting", event, event.target.id)

  for (const field of event.target.elements) {
    field.disabled = true
  }

});

document.addEventListener("turbo:submit-end", function (event) {
  // console.log("ending", event, event.target.id)

 
});


document.addEventListener('turbo:frame-render', () => {
  console.log("frame frame redner")

  const appointments = document.getElementById("appointments");
  if (appointments) {
    const existingAppointments = JSON.parse(
      document.querySelector("#appointments").dataset.appointments
    );

    flatpickr(".datepicker", {
      mode: "multiple",
      minDate: "today",
      disable: existingAppointments,
      dateFormat: "Y-m-d",
      inline: false,
    });
  }

  

  $(".ui.dropdown.host-signup").dropdown();

  $(".ui.dropdown").dropdown();

//   $('.long.modal')
//   .modal('show')
// ;

  addEventListener("trix-file-accept", function(e) {
    e.preventDefault();
  });
  
});

addEventListener("direct-upload:initialize", (event) => {
  const { target, detail } = event;
  const { id, file } = detail;
  target.insertAdjacentHTML(
    "beforebegin",
    `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename"></span>
    </div>
  `
  );
  target.previousElementSibling.querySelector(
    `.direct-upload__filename`
  ).textContent = file.name;
});

addEventListener("direct-upload:start", (event) => {
  const { id } = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  element.classList.remove("direct-upload--pending");
});

addEventListener("direct-upload:progress", (event) => {
  const { id, progress } = event.detail;
  const progressElement = document.getElementById(
    `direct-upload-progress-${id}`
  );
  progressElement.style.width = `${progress}%`;
});

addEventListener("direct-upload:error", (event) => {
  event.preventDefault();
  const { id, error } = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  element.classList.add("direct-upload--error");
  element.setAttribute("title", error);
});

addEventListener("direct-upload:end", (event) => {
  const { id } = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  element.classList.add("direct-upload--complete");
});











